document.documentElement.classList.replace('no-js', 'js');
['coarse', 'fine', 'none'].map((pointer) => {
  if (window.matchMedia(`(pointer: ${pointer})`).matches) {
    document.documentElement.classList.add(`pointer-${pointer}`);
  }
});
if (window.matchMedia(`(hover: none)`).matches) {
  document.documentElement.classList.add(`hover-off`);
} else {
  document.documentElement.classList.add(`hover-on`);
}
